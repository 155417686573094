<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-500px">
            <div>
                <p class="title">고객사 검색</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">

                <div class="dp-table w-100per">
                    <input type="text" class="w-300px h-30px float-left">
                    <button class="btn-default h-30px float-left ml-10">검색</button>
                </div>

                <div class="mt-50 mb-20">
                    <table class="tbl-light-list">
                        <colgroup>
                            <col width="*">
                            <col width="90">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>회사명</th>
                                <th>선택</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>삼성테크원</td>
                                <td>
                                    <button class="btn-default dp-inblock h-30px">선택</button>
                                </td>
                            </tr>
                            <tr>
                                <td>삼성전자(충남아산)</td>
                                <td>
                                    <button class="btn-default dp-inblock h-30px">선택</button>
                                </td>
                            </tr>
                            <tr>
                                <td>삼성경제연구소</td>
                                <td>
                                    <button class="btn-default dp-inblock h-30px">선택</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging></CarrotPaging>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'CustomerSearchPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>