<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>전자결재</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CustomerSearchPopup v-if="false" @close="comp.isShow=false"></CustomerSearchPopup>
                <CarrotTitle title="경비 입력" v-if="comp.info.idx==0"></CarrotTitle>
                <CarrotTitle title="경비 상세" v-if="comp.info.idx>0"></CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <table class="table-row">
                                <tbody>
                                    <tr>
                                        <th width="100">고객사</th>
                                        <td>
                                            <!-- <input type="text" maxlength="50" class="w-80per float-left">
                                            <button class="btn-default float-left ml-10 h-30px">검색</button> -->
                                            <CarrotCompany v-model="comp.info.idx_company"></CarrotCompany>
                                        </td>
                                        <th width="100">지출일</th>
                                        <td>
                                            <CarrotDatePicker v-model="comp.info.cdate"></CarrotDatePicker>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="dp-table w-100per mt-50">
                                <div class="float-left w-50per pr-25">
                                    <table class="table-col">
                                        <colgroup>
                                            <col width="100">
                                            <col width="100">
                                            <col width="155">
                                            <col width="220">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>구분</th>
                                                <th colspan="3">법인카드</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th class="th" rowspan="6">교통비</th>
                                                <th class="th1">주차비</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.corp_parkingfee"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.corp_parkingfee_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">택시</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.corp_taxi"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.corp_taxi_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">통행료</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.corp_toll"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.corp_toll_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">항공</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.corp_airfare"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.corp_airfare_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">버스</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.corp_busfare"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.corp_busfare_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">기타</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.corp_expense_etc"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.corp_expense_etc_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th">식대</th>
                                                <th class="th1">식대</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.corp_meals"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.corp_meals_desc" class="w-100per">
                                                </td>
                                            </tr>                                            
                                            <tr>
                                                <th class="th">기타</th>
                                                <th class="th1">기타비용</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.corp_etc"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.corp_etc_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th" rowspan="5">유류비</th>
                                                <th class="th1">출발지</th>
                                                <td class="txt-left" colspan="2">
                                                    <input type="text" maxlength="50" v-model="comp.info.corp_fuel_start" class="w-85per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">도착지</th>
                                                <td class="txt-left" colspan="2">
                                                    <input type="text" maxlength="50" v-model="comp.info.corp_fuel_destination" class="w-85per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">운행거리</th>
                                                <td class="txt-left" colspan="2">
                                                    <input type="text" maxlength="50" v-model="comp.info.corp_fuel_distance" class="w-85per ver-middle">
                                                    <span class="ml-10 ver-middle">km</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">연료</th>
                                                <td class="txt-left" colspan="2">
                                                    <label class="dp-block" v-for="(f, idx) in comp.fuel" :key="idx"><input type="radio" name="corp_fuel" v-model="comp.info.corp_fuel_type_price" :value="f.value"><span class="ml-5 mr-20"> {{f.label}}</span></label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">방문목적</th>
                                                <td class="txt-left" colspan="2">
                                                    <input type="text" maxlength="50" v-model="comp.info.corp_fuel_purpose" class="w-85per">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                                <div class="float-left w-50per pl-25">
                                    <table class="table-col">
                                        <colgroup>
                                            <col width="100">
                                            <col width="100">
                                            <col width="155">
                                            <col width="220">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>구분</th>
                                                <th colspan="3">현금&개인경비</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th class="th" rowspan="6">교통비</th>
                                                <th class="th1">주차비</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.indiv_parkingfee"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.indiv_parkingfee_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">택시</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.indiv_taxi"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.indiv_taxi_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">통행료</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.indiv_toll"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.indiv_toll_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">항공</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.indiv_airfare"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.indiv_airfare_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">버스</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.indiv_busfare"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.indiv_busfare_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">기타</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.indiv_expense_etc"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.indiv_expense_etc_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th">식대</th>
                                                <th class="th1">식대</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.indiv_meals"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.indiv_meals_desc" class="w-100per">
                                                </td>
                                            </tr>                                            
                                            <tr>
                                                <th class="th">기타</th>
                                                <th class="th1">기타비용</th>
                                                <td class="txt-left">
                                                    <CarrotCostInput class="costinput" v-model="comp.info.indiv_etc"></CarrotCostInput>
                                                    <span class="ml-10 ver-middle">원</span>
                                                </td>
                                                <td class="txt-left">
                                                    <input type="text" maxlength="50" v-model="comp.info.indiv_etc_desc" class="w-100per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th" rowspan="5">유류비</th>
                                                <th class="th1">출발지</th>
                                                <td class="txt-left" colspan="2">
                                                    <input type="text" maxlength="50" v-model="comp.info.indiv_fuel_start" class="w-85per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">도착지</th>
                                                <td class="txt-left" colspan="2">
                                                    <input type="text" maxlength="50" v-model="comp.info.indiv_fuel_destination" class="w-85per">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">운행거리</th>
                                                <td class="txt-left" colspan="2">
                                                    <input type="text" maxlength="50" v-model="comp.info.indiv_fuel_distance" class="w-85per ver-middle">
                                                    <span class="ml-10 ver-middle">km</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">연료</th>
                                                <td class="txt-left" colspan="2">
                                                    <label class="dp-block" v-for="(f, idx) in comp.fuel" :key="idx"><input type="radio" name="indiv_fuel" v-model="comp.info.indiv_fuel_type_price" :value="f.value"><span class="ml-5 mr-20"> {{f.label}}</span></label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="th1">방문목적</th>
                                                <td class="txt-left" colspan="2">
                                                    <input type="text" maxlength="50" v-model="comp.info.indiv_fuel_purpose" class="w-85per">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="mt-20">※유류비는 운행거리를 검색하여 정확하게 입력해주셔야 지급이 됩니다.</div>
                            <div class="mt-5">
                                <span class="btn-view">네이버 거리 검색 하러가기</span>
                            </div>

                            <router-link to="/documents/MyExpenseManagement"><button class="btn-default float-left mt-30">취소</button></router-link>
                            <button class="btn-default float-right mt-30" v-if="comp.info.idx==0" @click="comp.addExpense">등록</button>
                            <button class="btn-default float-right mt-30" v-if="comp.info.idx>0 && comp.auth=='Y' && !comp.check_indiv  && !comp.check_corp" @click="comp.modExpense">수정</button>
                            <button class="btn-default float-right mt-30" v-if="comp.info.idx>0 && comp.auth=='Y' && !comp.check_indiv  && !comp.check_corp" @click="comp.delExpense" >삭제</button>
                            <div class="clear"></div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src CustomerSearchPopup
import { onMounted, reactive } from 'vue'
import CustomerSearchPopup from '@/components/popup/payment/CustomerSearchPopup.vue'
import CarrotCostInput from '@/components/input/CarrotCostInput.vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue';
import CarrotCompany from '@/components/common/CarrotCompany.vue';

import axios from '@/plugins/axios.js'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';

export default {
    components: {
        CustomerSearchPopup,
        CarrotCostInput,
        CarrotDatePicker,
        CarrotCompany
    },
    setup() {
        const router = useRouter();
        const toast = useToast();
        const route = useRoute();

        const comp = reactive({
            // Popup 노출 여부
            isShow: true,
            fuel : [{value:1510, label: "휘발유(1,510)"}],
            info : {
                idx : 0,
                idx_company : 0,
                cdate : "",
                corp_parkingfee: 0,
                corp_parkingfee_desc : "",
                corp_taxi: 0,
                corp_taxi_desc: "",
                corp_toll: 0,
                corp_toll_desc: "",
                corp_airfare: 0,
                corp_airfare_desc: "",
                corp_busfare : 0,
                corp_busfare_desc : "",
                corp_expense_etc : 0,
                corp_expense_etc_desc : "",
                corp_meals : 0,
                corp_meals_desc : "",
                corp_etc : 0,
                corp_etc_desc : "",
                corp_fuel_start : "",
                corp_fuel_destination: "",
                corp_fuel_distance : "",
                corp_fuel_type : "",
                corp_fuel_type_price : 0,
                corp_fuel_purpose : "",
                indiv_parkingfee: 0,
                indiv_parkingfee_desc: "",
                indiv_taxi: 0,
                indiv_taxi_desc: "",
                indiv_toll: 0,
                indiv_toll_desc: "",
                indiv_airfare: 0,
                indiv_airfare_desc: "",
                indiv_busfare : 0,
                indiv_busfare_desc : "",
                indiv_expense_etc : 0,
                indiv_expense_etc_desc : "",
                indiv_meals : 0,
                indiv_meals_desc : "",
                indiv_etc : 0,
                indiv_etc_desc : "",
                indiv_fuel_start : "",
                indiv_fuel_destination: "",
                indiv_fuel_distance : "",
                indiv_fuel_type : "",
                indiv_fuel_type_price : 0,
                indiv_fuel_purpose : "",
            },
            getFuelCost : () => {
                let param = {tmr : new Date()};
                axios.post('/rest/resourceManagement/getFuelCostInfo', param).then((res)=>{
                    if( res.data.err == 0 ){
                        comp.fuel = [];
                        comp.fuel.push({value: res.data.gasoline, label: "휘발유(" + res.data.price_gasoline + "원)", ftype:"휘발유"});
                        comp.fuel.push({value: res.data.diesel, label: "경유(" + res.data.price_diesel + "원)", ftype:"경유"});
                        comp.fuel.push({value: res.data.lpg, label: "LPG(" + res.data.price_lpg + "원)", ftype:"LPG"});
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },
            getExpense : () => {
                let param = {
                    params : {idx : comp.info.idx}
                };
                axios.get('/rest/expense/getExpenseInfo', param).then((res)=>{
                    if( res.data.err == 0 ){
                        comp.info = res.data.info;
                        comp.auth = res.data.auth;
                      comp.check_corp = res.data.check_corp;
                      comp.check_indiv = res.data.check_indiv;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            checkValidation : () => {
                if(comp.info.idx_company<=0) {
                    toast.error("고객사는 필수 입력사항입니다.");
                    return false;
                }
                if(comp.info.cdate=='') {
                    toast.error("지출일은 필수 입력사항입니다.");
                    return false;
                }
                return true;
            },
            modExpense : () => {
                if(comp.checkValidation()) {
                    let param = {info : comp.info};
                    axios.post('/rest/expense/modExpense', param).then((res)=>{
                        if( res.data.err == 0 ){
                            router.push({ 
                                name:"documents-MyExpenseManagement",
                            });
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    });
                }
            },
            addExpense : () => {
                if(comp.checkValidation()) {

                    let param = {info : comp.info};
                    axios.post('/rest/expense/addExpense', param).then((res)=>{
                        if( res.data.err == 0 ){
                            router.push({ 
                                name:"documents-MyExpenseManagement",
                            });
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    });
                }
            },
            delExpense : () => {
                if(comp.checkValidation()) {
                    let param = {info : comp.info};
                    axios.post('/rest/expense/delExpense', param).then((res)=>{
                        if( res.data.err == 0 ){
                            router.push({ 
                                name:"documents-MyExpenseManagement",
                            });
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    });
                }
            },
            isEmpty : (str) => {
                if(typeof str == "undefined" || str == null || str == "")
                    return true;
                else
                    return false ;
            }

        });

        onMounted(() => {
            // Mounted
            comp.getFuelCost();
            if(!comp.isEmpty(route.params.id)) {
                comp.info.idx = route.params.id;
            }
            if(comp.info.idx>0) {
                comp.getExpense();
            }
        });

        return {comp};
    }
}
</script>

<style lang="scss" scoped>
    .costinput {
        width: 80%;
        display: inline-block;
    }
</style>