<template>
    <div class="costbox">
    <input type="text" class="w-100per" v-model="comp.price" @keyup="comp.update" @change="comp.update" :maxlength="12">
    </div>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'

export default {
    name: 'CarrotBankSelect',
    components: {
    },
    props: {
        modelValue: {
            type: [String, Number],
            default: "0",
        },
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {

        const comp = reactive({
            price : 0,
            update: () => {
                if(comp.price.toString() != "-"){
                    let price = comp.price.toString().replace(/,/g, "");
                    price = parseFloat(price);
                    console.log(comp.price.toString());
                    if(isNaN(price)) {
                        price = 0;
                    }
                    comp.price = price.toLocaleString();
                    emit('update:modelValue', price);
                }
            }
        });

        onMounted(() => {
            // Mounted
            comp.price = props.modelValue;
        });
        watch(() => props.modelValue, (newValue) => {
            if(comp.price != newValue) {
                comp.price = newValue;
                comp.update();
            }
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
.bank-select {
    width: 150px;
}
.costbox {
    display: inline-block;
}
</style>